<template>
  <v-container
    class="products-list-page"
    fluid>
    <v-row class="ma-0">
      <v-col
        class="count-stock-warehouses rounded pa-5"
        cols="12">
        <count-stock-warehouse-select v-model="formData.warehouses" />
      </v-col>
      <v-col
        class="count-stock-products rounded pa-5 mt-5"
        cols="12">
        <count-stock-product-select v-model="selectProducts" />
      </v-col>
      <v-col
        class="count-stock-container rounded pa-5 mt-5"
        cols="12">
        <v-textarea
          v-model="formData.note"
          label="Note"
          dense
          hide-details
          outlined />
      </v-col>
      <v-col class="count-stock-container mt-5">
        <v-col
          cols="3"
          offset="9">
          <v-btn
            color="success"
            width="100%"
            x-large
            @click="submit()">
            Submit
          </v-btn>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import CountStockProvider from '@/resources/CountStockProvider'
import CountStockWarehouseSelect from '../components/CountStockWarehouseSelect.vue'
import CountStockProductSelect from '../components/CountStockProductSelect.vue'

const CountStockService = new CountStockProvider()

export default {
  components: { CountStockWarehouseSelect, CountStockProductSelect },
  data () {
    return {
      loading: false,
      selectProducts: {
        products: [],
        isAllProduct: false
      },
      formData: {
        note: '',
        warehouses: [],
        products: []
      }
    }
  },
  methods: {
    ...mapActions({
      setErrorPage: 'Components/setErrorPage',
      setSnackbar: 'Components/setSnackbar'
    }),
    async submit () {
      try {
        this.loading = true
        const results = await CountStockService.create({
          ...this.formData,
          ...this.selectProducts
        })
        if (results) {
          this.$router.push({ name: 'CountStockList' })
        }
      } catch (error) {
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: `[${error.code}] ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.count-stock-container {
  background-color: white;
  box-shadow: 1px 2px 5px 0px #c2c2c2;
}
.count-stock-warehouses {
  width: 100%;
  background-color: white;
  box-shadow: 1px 2px 5px 0px #c2c2c2;
}
.count-stock-products {
  width: 100%;
  background-color: white;
  box-shadow: 1px 2px 5px 0px #c2c2c2;
}
</style>
