<template>
  <div>
    <h2>เลือกสินค้า (ทั้งหมด {{ total }} ชิ้น)</h2>
    <v-row class="my-4">
      <v-col
        cols="12"
        md="4"
        class="d-flex align-center">
        <CustomSwitch v-model="selectedProduct.isAllProduct" />
        <span class="ml-2">เลือกทั้งหมด</span>
      </v-col>
      <v-col
        cols="12"
        xs="12"
        sm="12"
        md="12"
        lg="7"
        xl="6"
        class="col-search">
        <search-by-tags-and-brand
          v-model="query"
          append-icon="mdi-magnify"
          tags-width="300px"
          :is-mobile="isMobile"
          @on-search="searchProduct()" />
      </v-col>
    </v-row>
    <div
      v-if="!selectedProduct.isAllProduct"
      class="count-stock-product-container">
      <div class="product-list-wrap">
        <div class="product-list">
          <div
            v-for="(product, i) in products"
            :key="i">
            <div class="product-header">
              <div class="product-img-wrapper">
                <img
                  width="40px"
                  :src="imgSrcCompute(product.photoUrls[0])"
                  alt="product-image">
              </div>
              <div
                class="px-2 d-flex justify-space-between w-full">
                {{ product.name }}
                <v-btn
                  color="success"
                  x-small
                  width="40px"
                  height="40px"
                  :loading="loading"
                  @click="selectProduct(product)">
                  +
                </v-btn>
              </div>
            </div>
            <div class="product-content pl-4 py-4">
              <v-col
                v-for="(sku, index) of product.skus"
                :key="`${i}-${index}`"
                class="row">
                <v-col
                  cols="4"
                  class="pa-0">
                  {{ sku.code || '' }}
                </v-col>
                <v-col
                  cols="2"
                  class="pa-0">
                  {{ sku.color || '' }}
                </v-col>
                <v-col
                  cols="2"
                  class="pa-0">
                  {{ sku.size }}
                </v-col>
                <v-col
                  cols="2"
                  class="text-right pa-0">
                  {{ sku.price | showNumberFormat() }}
                </v-col>
                <v-col
                  cols="2"
                  class="text-right pa-0 d-flex justify-end">
                </v-col>
              </v-col>
            </div>
          </div>
          <div class="my-4 text-center">
            <a
              class="underline"
              @click="loadmore()"> เพิ่มเติม </a>
          </div>
        </div>
      </div>
      <div class="product-btn-wrap">
        <v-btn
          class="mb-4"
          color="success"
          :loading="loading"
          @click="addAllItems">
          เลือกทั้งหมด ( {{ products.length }} )
          <v-icon color="#fff">
            mdi-arrow-right
          </v-icon>
        </v-btn>

        <v-btn
          color="error"
          :loading="loading"
          @click="removeAllItems">
          <v-icon
          >
            mdi-arrow-left
          </v-icon>
          นำออกทั้งหมด ( {{ selectedProduct.products.length }} )
        </v-btn>
      </div>
      <div class="product-list-wrap">
        <div class="product-list">
          <div
            v-for="(product, i) in selectedProduct.products"
            :key="i">
            <div class="product-header">
              <div class="product-img-wrapper">
                <img
                  width="40px"
                  :src="imgSrcCompute(product.photoUrls[0])"
                  alt="product-image">
              </div>
              <div
                class="px-2 d-flex justify-space-between w-full">
                {{ product.name }}
                <v-btn
                  color="error"
                  x-small
                  width="40px"
                  height="40px"
                  :loading="loading"
                  @click="removeProduct(i)">
                  -
                </v-btn>
              </div>
            </div>
            <div class="product-content pl-4 py-4">
              <v-col
                v-for="(sku, index) of product.skus"
                :key="`${i}-${index}`"
                class="row">
                <v-col
                  cols="4"
                  class="pa-0">
                  {{ sku.code || '' }}
                </v-col>
                <v-col
                  cols="2"
                  class="pa-0">
                  {{ sku.color || '' }}
                </v-col>
                <v-col
                  cols="2"
                  class="pa-0">
                  {{ sku.size || '' }}
                </v-col>
                <v-col
                  cols="2"
                  class="text-right pa-0">
                  {{ sku.price | showNumberFormat() }}
                </v-col>
                <v-col
                  cols="2"
                  class="text-right pa-0 d-flex justify-end">
                </v-col>
              </v-col>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ProductProvider from '@/resources/ProductProvider'
import SearchByTagsAndBrand from '@/components/SearchByTagsAndBrand.vue'
import CustomSwitch from '@/components/form/CustomSwitch.vue'

const ProductService = new ProductProvider()

export default {
  components: { SearchByTagsAndBrand, CustomSwitch },
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      loading: false,
      products: [],
      total: 0,
      query: {
        page: 0,
        limit: 100,
        sortBy: 'createdAt',
        sortOrder: 'desc',
        search: '',
        tags: [],
        tagOperation: 'OR',
        brand: 'all'
      },
      totalPage: 1
    }
  },
  computed: {
    selectedProduct: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    isMobile () {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm
    }
  },
  mounted () {
    this.fetchProducts(true)
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar'
    }),
    addAllItems () {
      const spliceProduct = this.products.splice(0, this.products.length)
      this.selectedProduct.products.push(...spliceProduct)
    },
    removeAllItems () {
      this.selectedProduct.products = []
    },
    imgSrcCompute (src) {
      return src || 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1200px-No-Image-Placeholder.svg.png'
    },
    loadmore () {
      this.query.page++
      this.fetchProducts()
    },
    async fetchProducts (isClear = false) {
      try {
        this.loading = true

        if (isClear) {
          this.query.page = 1
          this.products = []
        }

        const { data } = await ProductService.getProducts({
          page: this.query.page,
          limit: this.query.limit,
          search: this.query.search,
          tags: this.query.tags,
          tagOperation: this.query.tagOperation,
          brand: this.query.brand
        })
        this.totalPage = data.pages
        this.total = data.total
        this.products.push(
          ...data.results
            .map((re) => ({
              id: re.id,
              skus: re.skus.map((sku) => ({
                id: sku.id,
                code: sku.code,
                stock: 0,
                reserve: 0,
                count: 0,
                price: sku.price,
                option: sku.option,
                color: sku.color?.name || '',
                size: sku.size?.name || ''
              })),
              name: re.name,
              variant: re.variant,
              model: re.model,
              aliasId: re.aliasId,
              photoUrls: re.photoUrls,
              brand: re.brand
            }))
        )
      } catch (error) {
        console.error('fetchProducts', error)
        this.setSnackbar({
          value: true,
          message: `[FETCH-PRODUCTS]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    selectProduct (product) {
      this.selectedProduct.products.push(product)
    },
    removeProduct (index) {
      this.selectedProduct.products.splice(index, 1)
    },
    searchProduct () {
      this.fetchProducts(true)
    }
  }
}
</script>

<style scoped>
.underline {
  text-decoration: underline;
}
.w-full {
  width: 100%;
}
.product-img-wrapper {
  padding: 2px;
  width: 40px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #ababab;
}
.product-header {
  width: 100%;
  padding: 0 10px;
  background-color: #f3f3f3;
  height: 80px;
  display: flex;
  align-items: center;
}
.product-btn-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.count-stock-product-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.product-list-wrap {
  margin: 10px 0;
  min-width: 250px;
  width: calc(50% - 120px);
}
.product-list {
  padding: 10px;
  height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
}
.v-btn:not(.v-btn--round).v-size--x-small {
  min-width: unset !important;
}
</style>
