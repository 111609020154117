<template>
  <div>
    <h2>เลือกสาขา</h2>
    <div class="count-stock-warehouse-container">
      <div class="warehouse-list-wrap">
        <div class="text-right">
          จำนวน {{ availableWarehouses.length }} สาขา
        </div>
        <div class="warehouse-list">
          <div
            v-for="(warehouse, i) in availableWarehouses"
            :key="i"
            class="warehouse-left pa-2 ma-1"
            @click="selectWarehouse(warehouse)">
            <span>{{ warehouse.name }}</span>
            <v-icon>
              mdi-arrow-right
            </v-icon>
          </div>
        </div>
      </div>
      <div class="warehouse-list-wrap">
        <div class="text-right">
          เลือกแล้ว {{ selectedWarehouses.length }} สาขา
        </div>
        <div class="warehouse-list">
          <div
            v-for="(warehouse, i) in selectedWarehouses"
            :key="i"
            class="warehouse-right pa-2 ma-1"
            @click="removeWarehouse(i)">
            <v-icon>
              mdi-arrow-left
            </v-icon>
            <span>{{ warehouse.name }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WarehouseProvider from '@/resources/WarehouseProvider'

const WarehouseService = new WarehouseProvider()

export default {
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      loading: false,
      warehouses: []
    }
  },
  computed: {
    availableWarehouses () {
      return this.warehouses.filter((w) => !this.selectedWarehouses.find((s) => s.id === w.id))
    },
    selectedWarehouses: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  mounted () {
    this.initWarehouses()
  },
  methods: {
    async initWarehouses () {
      try {
        this.loading = true

        const { data } = await WarehouseService.getWarehouse()

        this.warehouses = data.filter((w) => !!w.id).map((w) => ({
          id: w.id,
          code: w.code,
          name: w.name
        }))
      } catch (error) {
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: `[WAREHOUSES]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    selectWarehouse (warehouse) {
      this.selectedWarehouses.push(warehouse)
    },
    removeWarehouse (index) {
      this.selectedWarehouses.splice(index, 1)
    }
  }
}
</script>

<style scoped>
.count-stock-warehouse-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.warehouse-list-wrap {
  margin: 10px 0;
  min-width: 250px;
  width: calc(50% - 20px);
}
.warehouse-list {
  padding: 10px;
  height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
}
.warehouse-left {
  background-color: #d9d9d9;
  color: black;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition-duration: .3s;
}
.warehouse-left:hover {
  background-color: #4caf50;
  color: white;
  transform: translateX(1rem);
}
.warehouse-right {
  background-color: #d9d9d9;
  color: black;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition-duration: .25s;
}
.warehouse-right:hover {
  background-color: #e91e63;
  color: white;
  transform: translateX(-1.25rem);
}
</style>
